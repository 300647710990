import "./footer.scss";
import React, {Component, Fragment} from 'react'
import GroupExample from "./ContactCards";


class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Fragment>
                <footer id={"footer"}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <h2>Nous joindre</h2>
                                <a href="https://www.sfl.ca/quebec-mauricie-appalaches/#main-content">Adresse de Plani-Concept Lebourneuf</a>
                                <p>825 Boulevard Lebourgneuf, bureau 500, Québec, QC G2J 0B9</p>
                                <a href="https://www.sfl.ca/quebec-mauricie-appalaches/#main-content">Adresse de Plani-Concept Lévis</a>
                                <p>1190-B Rue de Courcheval, suite 105, Lévis, QC G6W 0M6</p>
                                <p><b>Téléphone: </b>(418) 627-4447 poste: 274</p>
                                <p><b>Fax: </b> (418) 627-3327</p>
                                <p><b>Adresse courriel: </b>plani-holos@sfl.ca</p>
                                </div>
                            <div className="col">
                                <div className="iframe-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2729.2253343152456!2d-71.2825828!3d46.839253899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb897dd493ffffb%3A0xd15b2f581700dd8a!2s825%20Bd%20Lebourgneuf%20%23500%2C%20Qu%C3%A9bec%20City%2C%20QC%20G2J%200B9!5e0!3m2!1sen!2sca!4v1664727401774!5m2!1sen!2sca"
                                        width="500" height="300" style={{border: 0}} allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade" title={"Bureau Plani-Concept"}></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="logo_wrapper">
                            <h2>Nos partenaires</h2>
                            <GroupExample></GroupExample>
                        </div>
                    </div>
                    <br/>
                            <div className="container">
                                <div style={{fontStyle:"italic", fontWeight:10}}>
                                    <b>*</b> Les fonds communs de placement sont offerts par l’entremise de représentants en épargne collective rattachés à SFL Placements, Cabinet de services financiers.
                                </div>
                                
                            </div>
                            <div className="container">
                                <div style={{fontStyle:"italic", fontWeight:10}}>
                                    <p>

                                    <b>* Avis de non responsabilité:</b> Le présent site n’est pas la propriété de Desjardins Assurances***, ni de SFL Placements, Cabinet de services financiers. SFL Placements a le contrôle sur l'information contenue sur ce site par l'entremise des renseignements qui lui ont été fournis tel que prévu dans ses politiques et procédures. SFL Placements n'est pas le responsable du contenu de ce site qui n'est pas relié aux produits pour lesquels il est inscrit et n'est également pas responsable de surveiller ou de superviser les activités professionnelles extérieures de ses représentants.
                                    </p>
                                </div>
                                
                            </div>
                            <div className="container">
                                <div style={{fontStyle:"italic", fontWeight:10}}>
                                    <p>

                                    <b>* Avis de confidentialité:</b> Le présent avis s'applique au cabinet Plani-Concept, ainsi qu'à toutes les sociétés affiliées, ses dirigeants, directeurs, agents et employés, de même qu'à toute autre personne liée à la création du présent site.Plani-Concept sait à quel point la sécurité et la confidentialité de vos renseignements personnels sont importantes.Plani-Concept peut recueillir de l'information à des fins de compilations statistiques en vue de déterminer, par exemple, la popularité des pages de leur site, le nombre moyen de pages visitées et le temps moyen que chaque internaute passe dans le site. Aucun de ces renseignements ne peut être associé à vous à titre de visiteur individuel. Nous les utilisons seulement à des fins de vérification interne et pour améliorer notre site.Sondages et concours : Si vous répondez à un sondage ou vous inscrivez à un concours, nous connaîtrons votre opinion ou votre réponse ou toute autre information que vous voudrez bien nous fournir. Il est possible que nous utilisions ces renseignements dans les résultats du sondage et que nous les affichions dans notre site Web. Dans le cas d'un concours, nous utiliserons votre inscription aux fins du tirage.Nous apprécions que vous nous fassiez part de vos commentaires, questions et préoccupations. Votre opinion est importante pour nous.Veuillez prendre note que si vous nous envoyez un courriel, nous utiliserons votre adresse uniquement pour donner suite à votre requête.Si, pour quelque raison que ce soit, vous hésitez à soumettre des renseignements personnels par Internet, vous pouvez communiquer avec nous par téléphone ou par télécopieur.Plani-Concept se réserve le droit de modifier la présente politique en tout temps.
                                    </p>
                                </div>
                                
                            </div>
                        <div className={"container"}>
                            <div className={"copyRights"}>
                                    &copy; 2023 Tous droits réservés
                                </div>
                            </div>

                </footer>
            </Fragment>
        )
    }
}


export default Footer;