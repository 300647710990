import React from 'react'

const incrementId = (data) => {
    for(let i = 0; i < data.length; i++){
        data[i]["id"] = i;
    }
}
const getData = (data) =>{

return  (data.map((desc) =>
    <li>{desc}</li>
))

}
let services =  [
    {
        "serviceName" : "Placements",
        "serviceDescription"  : [
            "Politique de placement",
            "Profil d'investisseur",
            "Bonification de la valeur du patrimoine",
            "REER, CELI, REEE",
            "Régime enregistré d'épargne invalidité (REEI)",
            "Régime de retraite individuel (RRI)"
        ]
    },
    {
        "serviceName" : "Protection",
        "serviceDescription"  : [
            "Assurance vie",
            "Assurance invalidité et maladies graves",
            "Assurance santé et soins complémentaires",
            "Assurance individuelle ou collective"
        ]
    },
    {
        "serviceName" : "Fiscalité",
        "serviceDescription"  : [
            "Diagnostique générale",
            "Optimisation fiscale",
            "Planification des impôts"
        ]
    },
    {
        "serviceName" : "Succession",
        "serviceDescription"  : [
            "Transmission des actifs au décès",
            "Bilan successoral",
            "Dons planifiés",
            "Calculs de l'impôts au décès"
        ]
    },
    {
        "serviceName" : "Finances personnelles",
        "serviceDescription"  : [
            "Planification budgétaire",
            "Gestion de la dette",
            "Planification de projets"
        ]
    },
    {
        "serviceName" : "Retraite",
        "serviceDescription"  : [
            "Planifications des objectifs et besoins",
            "Gestion des risques",
            "Stratégies d'accumulation",
            "Prêt REER",
            "Stratégie de décaissement"
        ]
    }
]


incrementId(services)


function Services(){
        return (

            services.map((service) =>
                <div className={"service_card"} key={service["id"]}>
                    <h3>{service["serviceName"]}</h3>
                    <ul>
                        { getData(service["serviceDescription"])}
                    </ul>
                </div>
            )
        );
}



export default Services;
