import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const incrementId = (data) => {
    for(let i = 0; i < data.length; i++){
        data[i]["id"] = i;
    }
}


let valeurs =  [
    {
        "name" : "Qualité",
    },
    {
        "name" : "Intégrité",
    },
    {
        "name" : "Diligence",
    }
]

incrementId(valeurs)

function Valeurs(){
    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplay: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplay: false,
                    dots: false
                }
            },

        ]
    };

    return (
        <div className={"valeurs_container"}>
            {/*<Slider {...settings}>*/}
                <ul>
            {valeurs.map((valeur) =>
            <li className={"valeur_card"} key={valeur["id"]}>

                <h3>{valeur["name"]}</h3>

            </li>
            )}
                </ul>
            <div className={"container"}>

                <p>Notre priorité, c'est la satisfaction du client avant tout!</p>
            </div>
            {/*</Slider>*/}
        </div>
    );
}

export default Valeurs;